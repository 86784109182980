<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" />
    <v-toolbar flat>
      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title center-active>
          <v-tabs-slider></v-tabs-slider>
          <v-tab>{{ $t('layout.mainMenu.pending') }}</v-tab>
          <v-tab>{{ $t('layout.mainMenu.history') }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <leaves-table
              @manageLeave="manageLeave"
              @updateOptions="updateOptions"
              @deleteLeaveRequest="deleteLeaveRequest"
              :leaves="managerPendingLeaves"
              :tableName="leaveTables.MANAGER_PENDING_REQUESTS"
              :loading="loading"
            ></leaves-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <leaves-table
              @manageLeave="manageLeave"
              @updateOptions="updateOptions"
              :leaves="managerHistoryLeaves"
              :tableName="leaveTables.MANAGER_HISTORY_REQUESTS"
              :loading="loading"
            ></leaves-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import leavesTable from 'components/leaves/Table.vue';
import {mapActions, mapGetters} from 'vuex';
import {leaveStatus, leaveTables} from 'constants';

export default {
  name: 'managerLeaves',
  components: {
    leavesTable
  },
  data() {
    return {
      loading: true,
      leaveTables,
      title: {
        text: this.$t('layout.mainMenu.leaveRequests'),
        icon: 'mdi-calendar-question'
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.requests'),
          to: {name: 'LeavesRequests'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.leaves'),
          to: {name: 'LeavesRequests'},
          exact: true
        }
      ],
      tab: null
    };
  },
  computed: mapGetters(['managerHistoryLeaves', 'managerPendingLeaves', 'currentUser', 'isAdmin']),
  methods: {
    ...mapActions(['manageLeaveRequest', 'getLeaveRequests', 'destroyLeaveRequest']),
    deleteLeaveRequest(data) {
      this.destroyLeaveRequest({
        id: data.id, index: data.index, table: 'manager'
      });
    },
    manageLeave({data, modal}) {
      this.loading = true;
      this.manageLeaveRequest(data).then((status) => {
        if (this.successCode(status) && modal) {
          modal.show = false;
        }
        this.loading = false;
      });
    },
    async updateOptions(options) {
      this.loading = true;
      this.getLeaveRequests({
        ...options,
        filterBy: {
          ...options.filterBy,
          manager_id: this.isAdmin ? '' : this.currentUser.id,
          status: options.tableName === this.leaveTables.MANAGER_PENDING_REQUESTS ?
            leaveStatus.PENDING :
            [leaveStatus.REJECTED, leaveStatus.APPROVED, leaveStatus.CANCELED, leaveStatus.PARTIALLY_APPROVED]
        }
      }).then(() => {
        this.loading = false;
      });
    }
  }
};
</script>

