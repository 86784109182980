var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],attrs:{"modal":_vm.modal},on:{"submit-modal":_vm.submitCancelNote}}),_c('table-filter',{attrs:{"items":_vm.yearOptions,"label":_vm.$t('models.leaveRequest.attributes.year')},on:{"filterTable":_vm.filterTable}}),_c('v-card',{staticClass:"rounded-card",attrs:{"flat":"","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.visibleHeaders,"items":_vm.leaves.items,"options":_vm.options,"server-items-length":_vm.leaves.count,"loading":_vm.loading,"expanded":_vm.expanded,"no-data-text":_vm.$t('messages.emptyState', { entity: _vm.$tc('models.leaveRequest.entity', 2) }),"no-results-text":_vm.$t('messages.emptyState', { entity: _vm.$tc('models.leaveRequest.entity', 2) }),"show-expand":""},on:{"update:options":[function($event){_vm.options=$event},_vm.updateOptions],"update:expanded":function($event){_vm.expanded=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"45"}},[_c('app-avatar',{staticClass:"no-padding",attrs:{"url":_vm.getFileUrl(item.user.avatar),"alternative":item.user.initials}})],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(item.user.name)}})],1)]}},{key:"item.requestedDates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startDateWithTimezone)+" "),(item.startDateWithTimezone !== item.endDateWithTimezone && item.unit !== _vm.leaveUnits.HOURS)?[_vm._v(" - "+_vm._s(item.endDateWithTimezone)+" ")]:_vm._e(),(item.unit !== _vm.leaveUnits.FULL_DAY)?[_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t(("models.leaveRequest.attributes.units." + (item.unit)))))])]:_vm._e()]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration.convention)+" ("+_vm._s(item.duration.hours)+_vm._s(_vm.$t('models.leaveRequest.attributes.hours'))+") ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableName !== _vm.leaveTables.MANAGER_PENDING_REQUESTS),expression:"tableName !== leaveTables.MANAGER_PENDING_REQUESTS"}],attrs:{"small":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":_vm.statuses[item.status].color}},[_vm._v(_vm._s(_vm.statuses[item.status].icon))]),_vm._v(" "+_vm._s(_vm.$t(("models.leaveRequest.attributes.statuses." + (item.status))))+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canManage(item.status, item.user.id) && _vm.noActionTakenOnAnyDateByThisManager(item.dates))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.manageDates(_vm.leaveStatus.APPROVED, item, _vm.leaves.items.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1):_vm._e(),(_vm.canManage(item.status, item.user.id) && _vm.noActionTakenOnAnyDateByThisManager(item.dates))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.manageDates(_vm.leaveStatus.REJECTED, item, _vm.leaves.items.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-thumb-down-outline")])],1):_vm._e(),(_vm.canCancel(item.status, item.user.id))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.cancelDate(item, _vm.leaves.items.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e(),(_vm.canUpdateOrDelete(item.canEdit))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateLeaveRequest(item)}}},[_c('v-icon',[_vm._v("mdi-pen")])],1):_vm._e(),(_vm.canUpdateOrDelete(item.canEdit))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.deleteLeaveRequest(item, _vm.leaves.items.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._l((item.dates),function(leaveDetails,j){return _c('tr',{key:j,staticClass:"row custom-border-bottom"},[_c('v-col',{staticClass:"text-start align-middle-vertical",attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(leaveDetails.dateWithTimezone)+" "),(item.startTime && item.endTime)?[_vm._v(" ["+_vm._s(item.startTime)+" - "+_vm._s(item.endTime)+"] ")]:_vm._e()],2),_c('v-col',{staticClass:" align-middle-vertical",attrs:{"cols":"2"}},[(item.status === _vm.leaveStatus.APPROVED && !item.leave.requiresApproval)?_c('v-badge',{attrs:{"avatar":"","bordered":"","bottom":"","overlap":"","color":"background"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_c('v-icon',{attrs:{"x-small":"","color":_vm.statuses[_vm.leaveStatus.APPROVED].color}},[_vm._v(" "+_vm._s(_vm.statuses[_vm.leaveStatus.APPROVED].icon)+" ")])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"size":"35","color":"primary"}},[_c('v-icon',{attrs:{"color":"background"}},[_vm._v("mdi-robot")])],1)],1):_vm._l((leaveDetails.managers),function(ref,i){
var manager = ref.manager;
var response = ref.response;
return _c('v-badge',{key:i,staticClass:"mx-2",attrs:{"avatar":"","bordered":"","bottom":"","overlap":"","color":"background"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_c('v-icon',{attrs:{"x-small":"","color":_vm.statuses[response].color}},[_vm._v(_vm._s(_vm.statuses[response].icon))])],1)]},proxy:true}],null,true)},[(_vm._.isNil(manager))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"35","color":"primary"}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"background"}},[_vm._v("mdi-account-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('views.leaves.inactiveUser')))])]):_c('v-avatar',{attrs:{"size":"35"}},[_c('app-avatar',{attrs:{"url":_vm.getFileUrl(manager.avatar),"alternative":manager.initials,"size":"xsmall","tooltip":manager.name}})],1)],1)})],2),_c('v-col',{staticClass:"text-center align-middle-vertical",attrs:{"cols":"2"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.canManage(leaveDetails.status, item.user.id)),expression:"canManage(leaveDetails.status, item.user.id)"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.manageDates(_vm.leaveStatus.APPROVED , item, _vm.leaves.items.indexOf(item), j)}}},[_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.canManage(leaveDetails.status, item.user.id)),expression:"canManage(leaveDetails.status, item.user.id)"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.manageDates(_vm.leaveStatus.REJECTED,  item, _vm.leaves.items.indexOf(item), j)}}},[_c('v-icon',[_vm._v("mdi-thumb-down-outline")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.canCancel(leaveDetails.status, item.user.id)),expression:"canCancel(leaveDetails.status, item.user.id)"}],attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.cancelDate(item, _vm.leaves.items.indexOf(item), j)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1),_c('v-col',{attrs:{"cols":"1"}})],1)}),(item.note)?_c('tr',{staticClass:"row note"},[_c('v-col',[_c('h4',[_vm._v(_vm._s(_vm.$t('models.leaveRequest.attributes.note'))+": ")]),_c('p',{staticClass:"text-caption"},[_vm._v(_vm._s(item.note))])])],1):_vm._e()],2)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }